import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';
import { getFirestore } from 'firebase/firestore';
import { getAuth,signInWithPopup, GoogleAuthProvider  } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyD0eyQPpKZbG1w7L25bSX95FBTsdiLh8O0",
  authDomain: "islamic-thijarat.firebaseapp.com",
  projectId: "islamic-thijarat",
  storageBucket: "islamic-thijarat.appspot.com",
  messagingSenderId: "383170369951",
  appId: "1:383170369951:web:f2c6da70011b11eb0e05aa",
  measurementId: "G-3N1LZNKSLQ"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);
const db = getDatabase(app);
const auth = getAuth(app);
const googleAuthProvider = new GoogleAuthProvider(app);

export { firestore,db,auth,googleAuthProvider};